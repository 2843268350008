<template>
  <div class="sub-header-thin-div">
    <v-card :class="isMobile ? 'mx-auto my-10' : 'mx-auto my-20'" :max-width="isMobile ? '90%' : '70%'">
      <br/>
      <v-card-text :class="isMobile ? 'my-5 text-center' : 'my-10 text-center'">
        <p :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`">{{ $t('jobs_header') }}</p>
      </v-card-text>
      <div class="m-5 job-div" :max-width="isMobile ? '90%' : '60%'" v-for="(job, index) in jobs" :key="(job, index)" @click="$router.push(`/jobs_detail/${index}`)">
        <p :class="isMobile ? 'font-15' : 'font-20'">
          <b>{{ job.title }}</b>
        </p>
        <p :class="isMobile ? 'font-12' : 'font-18'">
          {{ job.content }}
        </p>
      </div>
      <p class="m-10">&nbsp;</p>
    </v-card>
  </div>
</template>

<style scoped>
.job-div {
	padding: 10px;
  cursor: pointer;
	background: white !important;
  border: 1px solid #45154D !important;
	border-radius: 10px !important;
	box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
	-moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
	-webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
}
</style>

<script>
export default {
  name: 'JobList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      jobs: [
        {
          title: '- Country Manager',
          content: 'Mumbai / Operations / Experienced',
        },
        {
          title: '- Social Media Content Manager',
          content: 'München / Intern / Experienced',
        },
        {
          title: '- Marketing Manager',
          content: 'München / Operations / Experienced',
        },
        {
          title: '- Creator & Content Strategies',
          content: 'München / Operations / Advanced',
        },
        {
          title: '- Marketing Manager',
          content: 'Berlin / Operations / Experienced',
        },
        {
          title: '- Creator & Content Strategies',
          content: 'Istanbul / Operations / Advanced',
        },
        {
          title: '- Partnerships Manager',
          content: 'Istanbul / Sales / Experienced',
        },
        {
          title: '- Product Manager',
          content: 'Los Angeles / Product / Experienced',
        },
        {
          title: '- Art Director/Designer',
          content: 'Los Angeles / Design / Experienced',
        },
        {
          title: '- Video Editor',
          content: 'Berlin / Marketing / Experienced',
        }
      ]
    };
  }
};
</script>
